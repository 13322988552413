@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #2A2B2A;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #F8F4E3;
    border: 2px solid #F8F4E3;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .scrollbar-hidden::-webkit-scrollbar-track {
    background: #2A2B2A;
  }

  .scrollbar-hidden::-webkit-scrollbar-thumb {
    background: #F8F4E3;
    border: 2px solid #F8F4E3;
  }
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

* {
  outline: none;
}

.app-loading {
  display: flex;
  justify-content: center;
  background-color: #2A2B2A;
  color: #F8F4E3;
  height: 100vh;
}

.app-loading div {
  margin-top: 30vh;
}
